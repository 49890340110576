<template>
  <div >
    <el-menu default-active="1-4-1" class="el-menu-vertical-demo" :collapse="isCollapse">  

      <div>
        <div class="group" v-if="XPermission(['管理员', '获取角色列表', '获取用户列表', '获取权限列表', '获取机器列表'])">
          {{ isCollapse ? '系统' : '系统管理' }}
        </div>

        <router-link to="/manage/role" v-if="XPermission(['获取角色列表'])">
          <el-menu-item index="10">
              <i class="iconfont icon-jiaose"></i>
              <span slot="title">角色管理</span>
          </el-menu-item>
        </router-link>

        <router-link to="/manage/member" v-if="XPermission(['获取用户列表'])">
          <el-menu-item index="20">
            <i class="iconfont icon-user"></i>
            <span slot="title">账号管理</span>
          </el-menu-item>
        </router-link>

        <router-link to="/manage/permission" v-if="XPermission(['获取权限列表'])">
          <el-menu-item index="30">
            <i class="iconfont icon-quanxian"></i>
            <span slot="title">权限管理</span>
          </el-menu-item>
        </router-link>

        <router-link to="/manage/machine" v-if="XPermission(['获取机器列表'])">
          <el-menu-item index="40">
            <i class="iconfont icon-anjian"></i>
            <span slot="title">客户机管理</span>
          </el-menu-item>
        </router-link>
      </div>

      <div>
        <div class="group">
          {{ isCollapse ? '题目' : '题目管理' }}
        </div>

        <router-link to="/manage/question/store" v-if="XPermission(['获取题库列表']) && tearchDisabled">
          <el-menu-item index="50">
            <i class="iconfont icon-tikuguanli"></i>
            <span slot="title">题库管理</span>
          </el-menu-item>
        </router-link>  

        <router-link to="/manage/question/teacher" v-if="XPermission(['获取题库列表'])">
          <el-menu-item index="70">
            <i class="iconfont icon-lunwentimu"></i>
            <span slot="title">老师答题</span>
          </el-menu-item>
        </router-link>  

        <div @click="trigerCollapse" class="triger"> 
          <span v-if="!isCollapse" style="padding-right: 15px"> <i class="el-icon-arrow-left"></i>收起</span>
          <span v-else>展开 <i class="el-icon-arrow-right"></i></span>
        </div>

      </div>
      
    </el-menu>
  </div>
</template>

<script>
export default {
  name: 'SideBar',
  props: ["role"],
  components: {

  },
  data() {
    return {
      isCollapse: JSON.parse(localStorage.getItem("sat_isCollapse")) || false
      // permissions: ""
    }
  },
  computed: {
    tearchDisabled() {
     let isFind =  this.role.filter(item => item.explain == '老师')
     return !isFind.length
    }
  },
  mounted() {
    // console.log(this.role)
    // this.getPermissions()
  },
  methods: {
    trigerCollapse() {
      this.isCollapse = !this.isCollapse
      localStorage.setItem("sat_isCollapse", JSON.stringify(this.isCollapse))
    },
    // getPermissions() {
    //   if (localStorage.getItem("sat_manage_my_auth")) {
    //     this.permissions = JSON.parse(localStorage.getItem("sat_manage_my_auth")) || ""
    //   }
    //   console.log(this.permissions)
    // },
    // checkPermissions(roles) {
    //   if (this.permissions) {
    //     let hasPermissions = this.permissions.userPermissions.filter(item => roles.includes(item.explain))
    //     return hasPermissions.length ? true : false
    //   }
    // }
  },
}
</script>

<style lang='scss' scoped>
// .el-menu-vertical {
//   width: 280px;
//   height: calc(100vh - 60px);
// }
.el-menu-vertical-demo {
  height: calc(100vh - 62px);
}
.el-menu-vertical-demo:not(.el-menu--collapse) {
  width: 280px;
}
.group {
  display: flex;
  align-items: center;
  width: 100%;
  height: 30px;
  color: #999;
  padding-left: 20px;
  margin-top: 20px;
}
.el-menu-item .iconfont {
  font-size: 16px;
  margin-right: 6px;
}
.el-menu-item.is-active {
  background: #0052D9;
  color: #fff;
}
.router-link-exact-active, .router-link-active {
  display: inline-block;
  width: 100%;
  background: #0052D9;
  .iconfont, .el-menu-item {
    color: #fff;
  }
}
.triger {
  width: 100%;
  height: 40px;
  line-height: 40px;
  text-align: right;
  position: absolute;
  bottom: 0;
  border-top: 1px solid #eee;
  cursor: pointer;
}
</style>