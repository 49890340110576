import machine from './machine'
import member from './member'
import role from './role'
import permission from './permission'

const system = {
  machine,
  member,
  role,
  permission
}

export default system