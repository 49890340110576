import request from '@/utils/request'

const machine = {

 // 客户机列表查询
  machineList(data) {
    return request({
      url: `/user/machine/list`,
      method: 'post',
      data
    })
  },
}
export default machine
