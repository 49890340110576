/*
 * @Author: Robin.lau
 * @Email: 329080237@qq.com
 * @Wechat: 15618110227
 */
/*
    前端字典表维护，用于表格等地方展示
*/

export const staffStatusList = [
  {name:"草稿",value:'10'},
  {name:"已提交",value:'20'},
  {name:"退回",value:'30'},
  {name:"修改",value:'40'},
  {name:"配送中",value:'50'},
  {name:"关闭",value:'60'},
  {name:"完成",value:'70'}
]

export const status_code = [
    {name:"待上线",value:'10'},
    {name:"正常",value:'20'},
    {name:"维修中",value:'30'},
    {name:"失联",value:'40'}
];

export const workwearStatus = [
  {name:"正常",value:'10'},
  {name:"临时",value:'20'},
  {name:"报废",value:'30'},
]

export const workwearFlowStatus = [
  {name:"待取",value:'10'},
  {name:"在用",value:'20'},
  {name:"待回收",value:'30'},
  {name:"洗涤",value:'40'},
]
